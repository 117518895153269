export function gtmPageView(cPath, title) {
  window &&
    window.hasFired &&
    window.dataLayer.push({
      event: "Pageview",
      pagePath: cPath,
      pageTitle: title,
    });
}

export function gtmEvent(eC, eA, eL) {
  window &&
    window.hasFired &&
    window.dataLayer.push({
      event: "send",
      eventCategory: eC,
      eventAction: eA,
      eventLabel: eL,
    });
}

export const pageview = (url) => {
  try {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({
        event: "pageview",
        page: window.location.pathname,
      });
    } else {
      throw new Error("dataLayer is not defined");
    }
  } catch (e) {
    console.error({
      event: "pageview",
      page: window.location.pathname,
      e,
    });
  }
};

const pushToDataLayer = (eventName) => {
  try {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event: eventName });
    }
  } catch (e) {
    console.error(`Error pushing event '${eventName}' to dataLayer`, e);
  }
};

export const SignUpEvent = () => pushToDataLayer("sign_up_event");
export const GoogleSignInEvent = () => pushToDataLayer("google_sign_in");
export const ManualSignInEvent = () => pushToDataLayer("manual_sign_in");
export const EmailSubscribeEvent = () => pushToDataLayer("email_subscribe_event");
export const AnonymousUserHardPayWallEvent = () => pushToDataLayer("anonymous_user_hardpaywall");
export const LoginUserHardPayWallEvent = () => pushToDataLayer("login_user_hardpaywall");
export const CommentsEvent = () => pushToDataLayer("comments_opened");
export const DarkModeEvent = () => pushToDataLayer("dark_mode_selected");
export const HomeScreenSubscribeBtn = () => pushToDataLayer("home_screen_subscribe_btn");
export const AppealBoxPatron = () => pushToDataLayer("appeal_box_patron");
export const AppealBoxSubscriber = () => pushToDataLayer("appeal_box_subscriber");
export const MagazinePrintSubsribe = () => pushToDataLayer("magazine_print_subscribe");
export const PromotionClaim = () => pushToDataLayer("promotion_claim");
export const BottomPromotionClaim = () => pushToDataLayer("bottom_promotion_claim");
export const CenterPromotionClaim = () => pushToDataLayer("center_promotion_claim");
export const MagicLink = () => pushToDataLayer("magic_link");
