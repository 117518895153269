// import React, { useState, useEffect } from "react";
// import { Link } from "@quintype/components";
// import { RedirectIcon, CloseIcon } from "../Icons/Icon";
// import { PromotionClaim } from "../../utils/gtm";
// import { useSelector, useDispatch } from "react-redux";
// import get from "lodash/get";

// const Promotionbox = ({ promo_data }) => {
//   const getEmail = () => {
//     if (typeof window !== "undefined" && window.localStorage) {
//       const storedUser = sessionStorage.getItem("SUBSCRIBED_USER");
//       return storedUser ? JSON.parse(storedUser) : false;
//     }
//     return null;
//   };

//   const getUserProfileId = () => {
//     if (typeof window !== "undefined" && window.sessionStorage) {
//       const storedProfileId = sessionStorage.getItem("USER_PROFILE_ID");
//       return storedProfileId ? JSON.parse(storedProfileId) : null;
//     }
//     return null;
//   };

//   const isSubscribedState = useSelector((state) => get(state, ["isSubscribedUser"], null));

//   console.log("isSubscribedState", isSubscribedState);

//   console.log("getEmail", getEmail());

//   const isSubscribed = isSubscribedState === null ? getEmail() : isSubscribedState;

//   console.log("isSubscribed", isSubscribed);

//   const [isOpen, setIsOpen] = useState(false);
//   const [remainingTime, setRemainingTime] = useState({
//     hours: "0",
//     minutes: "0",
//     seconds: "0",
//   });

//   useEffect(() => {
//     const countdownEnds = new Date(promo_data.countdown_ends);
//     const currentTime = new Date();
//     const timeDifferenceMs = countdownEnds - currentTime;

//     setTimeout(() => {
//       if (timeDifferenceMs > 0) {
//         setIsOpen(true);

//         const timer = setTimeout(() => {
//           setIsOpen(false);
//         }, timeDifferenceMs);

//         const interval = setInterval(() => {
//           const timeLeft = Math.max(countdownEnds - new Date(), 0);
//           const hours = Math.floor(timeLeft / (1000 * 60 * 60));
//           const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
//           const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
//           setRemainingTime({
//             hours: hours.toString().padStart(2, "0"),
//             minutes: minutes.toString().padStart(2, "0"),
//             seconds: seconds.toString().padStart(2, "0"),
//           });
//         }, 1000);

//         return () => {
//           clearTimeout(timer);
//           clearInterval(interval);
//         };
//       }
//     }, 5000);
//   }, [promo_data]);

//   const handleClose = () => {
//     setIsOpen(false);
//   };

//   return promo_data.show_promotion ? (
//     <div
//       className={`duration-900 promo-animated-div fixed top-0 z-[99999999] flex w-full p-4 transition-transform ease-in-out ${
//         isOpen && !isSubscribed ? "block" : "hidden"
//       }`}
//       style={{
//         background: `linear-gradient(to right, ${promo_data.start_color}, ${promo_data.end_color})`,
//         color: `${promo_data.text_color}`,
//       }}
//     >
//       <div className="flex flex-1 items-center justify-center gap-3 text-sm mobile:flex-col">
//         <p>{promo_data.promotion_title}</p>
//         <div className="flex items-center justify-center gap-3 text-sm text-white mobile:flex-col">
//           <div className="flex items-center justify-center">
//             <Link
//               href={promo_data.redirect_url}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex gap-1"
//               onClick={() => PromotionClaim()}
//             >
//               {promo_data.button.button_text}
//               <RedirectIcon />
//             </Link>
//             <span className="mx-1 mobile:hidden">|</span>
//           </div>
//         </div>
//         <span className="bg-[rgba(255,255,255,0.2)] px-2 py-[4px]">
//           {`${remainingTime.hours}H ${remainingTime.minutes}M ${remainingTime.seconds}S`}
//         </span>
//       </div>
//       <button className="cursor-pointer text-sm font-semibold" onClick={handleClose}>
//         <CloseIcon />
//       </button>
//     </div>
//   ) : null;
// };

// export default Promotionbox;

import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { Link } from "@quintype/components";
import { RedirectIcon, CloseIcon } from "../Icons/Icon";
import { PromotionClaim } from "../../utils/gtm";
import { useSelector } from "react-redux";
import get from "lodash/get";

const Promotionbox = ({ promo_data }) => {
  const getEmail = useCallback(() => {
    if (typeof window !== "undefined" && window.sessionStorage) {
      const storedUser = sessionStorage.getItem("SUBSCRIBED_USER");
      return storedUser ? JSON.parse(storedUser) : false;
    }
    return null;
  }, []);

  const isSubscribedState = useSelector((state) => get(state, ["isSubscribedUser"], null));

  const isSubscribed = useMemo(
    () => (isSubscribedState === null ? getEmail() : isSubscribedState),
    [isSubscribedState, getEmail]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    if (!promo_data.show_promotion || isSubscribed) {
      return;
    }

    const countdownEnds = new Date(promo_data.countdown_ends);
    const currentTime = new Date();
    const timeDifferenceMs = countdownEnds - currentTime;

    if (timeDifferenceMs <= 0) {
      return;
    }

    const openTimeout = setTimeout(() => {
      setIsOpen(true);
    }, 1000);

    const closeTimeout = setTimeout(() => {
      setIsOpen(false);
    }, timeDifferenceMs);

    const interval = setInterval(() => {
      const timeLeft = Math.max(countdownEnds - new Date(), 0);
      const hours = Math.floor(timeLeft / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      setRemainingTime({
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      });
    }, 1000);

    return () => {
      clearTimeout(openTimeout);
      clearTimeout(closeTimeout);
      clearInterval(interval);
    };
  }, [promo_data, isSubscribed]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (!promo_data.show_promotion || isSubscribed || !isOpen) {
    return null;
  }

  return (
    <div
      className="duration-900 promo-animated-div fixed top-0 z-[99] flex w-full p-4 transition-transform ease-in-out"
      style={{
        background: `linear-gradient(to right, ${promo_data.start_color}, ${promo_data.end_color})`,
        color: `${promo_data.text_color}`,
        zIndex: 99,
      }}
    >
      <div className="flex flex-1 items-center justify-center gap-3 text-sm mobile:flex-col">
        <p>{promo_data.promotion_title}</p>
        <div className="flex items-center justify-center gap-3 text-sm text-white mobile:flex-col">
          <div className="flex items-center justify-center">
            <Link
              href={promo_data.redirect_url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex gap-1"
              onClick={PromotionClaim}
            >
              {promo_data.button.button_text}
              <RedirectIcon />
            </Link>
            <span className="mx-1 mobile:hidden">|</span>
          </div>
        </div>
        <span className="bg-[rgba(255,255,255,0.2)] px-2 py-[4px]">
          {`${remainingTime.hours}H ${remainingTime.minutes}M ${remainingTime.seconds}S`}
        </span>
      </div>
      <button className="cursor-pointer text-sm font-semibold" onClick={handleClose}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default React.memo(Promotionbox);
