export const adUnits = {
  "desktop-sp-top-970x90": {
    adUnit: "SM_Desktop_SP_Top_970x90",
    sizes: [
      [970, 90],
      [728, 90],
    ],
    desktop: true,
  },
  "desktop-hp-mid-970x90": {
    adUnit: "SM_Desktop_HP_MID_970x90",
    sizes: [
      [970, 90],
      [728, 90],
    ],
    desktop: true,
  },

  "desktop-hp-right-300x250": {
    adUnit: "SM_Desktop_HP_RightATF_300x250",
    sizes: [[300, 250]],
    desktop: true,
  },

  "desktop-featured-right-300x250": {
    adUnit: "SM_Desktop_HP_Featured_RightATF_300x250",
    sizes: [[300, 250]],
    desktop: true,
  },

  "desktop-hp-btf1-970x90": {
    adUnit: "SM_Desktop_HP_BTF1_970x90",
    sizes: [
      [970, 90],
      [728, 90],
    ],
    desktop: true,
  },

  "desktop-hp-btf2-970x90": {
    adUnit: "SM_Desktop_HP_BTF2_970x90",
    sizes: [
      [970, 90],
      [728, 90],
    ],
    desktop: true,
  },

  "desktop-hp-mid2-970x90": {
    adUnit: "SM_Desktop_HP_MID2_970x90",
    sizes: [
      [970, 90],
      [728, 90],
    ],
    desktop: true,
  },
  "desktop-hp-mid3-970x90": {
    adUnit: "SM_Desktop_HP_MID3_970x90",
    sizes: [
      [970, 90],
      [728, 90],
    ],
    desktop: true,
  },

  // Desktop article page ad units

  "desktop-ap-rightatf-300x250": {
    adUnit: "SM_Desktop_AP_RightATF_300x250",
    sizes: [[300, 250]],
    desktop: true,
  },

  "desktop-ap-right-300x600": {
    adUnit: "SM_Desktop_HP_RightBTF_300x600",
    sizes: [
      [300, 600],
      [300, 250],
    ],
    desktop: true,
  },

  //mobile home page adunits
  "mobile-hp-top-300x250": {
    adUnit: "SM_Mobile_HP_Top_300x250",
    sizes: [[300, 250]],
    desktop: false,
  },

  "mobile-hp-mid-300x250": {
    adUnit: "SM_Mobile_HP_MID_300x250",
    sizes: [[300, 250]],
    desktop: false,
  },

  "mobile-hp-mid2-300x250": {
    adUnit: "SM_Mobile_HP_MID2_300x250",
    sizes: [[300, 250]],
    desktop: false,
  },

  "mobile-hp-mid3-300x250": {
    adUnit: "SM_Mobile_HP_MID3_300x250",
    sizes: [[300, 250]],
    desktop: false,
  },

  "mobile-hp-btf1-300x250": {
    adUnit: "SM_Mobile_HP_BTF1_300x250",
    sizes: [[300, 250]],
    desktop: false,
  },

  "mobile-hp-btf2-300x250": {
    adUnit: "SM_Mobile_HP_BTF2_300x250",
    sizes: [[300, 250]],
    desktop: false,
  },

  // "desktop-hp-btf-970x90": {
  //   adUnit: "SM_Desktop_BTF_970x90",
  //   sizes: [
  //     [970, 90],
  //     [728, 90],
  //   ],
  // },

  // "desktop-ap-top-970x90": {
  //   adUnit: "SM_Desktop_AP_Top_970x90",
  //   sizes: [
  //     [970, 90],
  //     [728, 90],
  //   ],
  // },
  // "desktop-sp-mid-300x250": {
  //   adUnit: "SM_Mobile_SP_MID_300x250",
  //   sizes: [[300, 250]],
  // },

  // "desktop-sp-right-300x600": {
  //   adUnit: "SM_Desktop_SP_RightATF_300x600",
  //   sizes: [[300, 600]],
  // },
  // "desktop-ap-rightatf-300x250": {
  //   adUnit: "SM_Desktop_AP_RightATF_300x250",
  //   sizes: [[300, 250]],
  // },

  // "desktop-ap-btf-970x90": {
  //   adUnit: "SM_Desktop_AP_BTF_970x90",
  //   sizes: [
  //     [970, 90],
  //     [728, 90],
  //   ],
  // },

  // "mobile-hp-btf-300x250": {
  //   adUnit: "SM_Mobile_HP_BTF_300x250",
  //   sizes: [[300, 250]],
  // },
  // "mobile-ap-top-320x100": {
  //   adUnit: "SM_Mobile_AP_TOP_320x100",
  //   sizes: [[320, 100]],
  // },
  // "mobile-ap-mid-300x250": {
  //   adUnit: "SM_Mobile_AP_MID_300x250",
  //   sizes: [[300, 250]],
  // },
  // "mobile-ap-mid2-300x250": {
  //   adUnit: "SM_Mobile_AP_MID2_300x250",
  //   sizes: [[300, 250]],
  // },
  // "mobile-ap-mid3-300x250": {
  //   adUnit: "SM_Mobile_AP_MID3_300x250",
  //   sizes: [[300, 250]],
  // },
  // "mobile-ap-btf-300x250": {
  //   adUnit: "SM_Mobile_AP_BTF_300x250",
  //   sizes: [[300, 250]],
  // },
  // "mobile-ap-top-300x250": {
  //   adUnit: "SM_Mobile_AP_TOP_300x250",
  //   sizes: [[300, 250]],
  // },
  // "mobile-ap-mid4-300x250": {
  //   adUnit: "SM_Mobile_AP_MID4_300x250",
  //   sizes: [[300, 250]],
  // },

  // "desktop-ap-mid4-728x90": {
  //   adUnit: "SM_Desktop_AP_BTF_728x90",
  //   sizes: [[728, 90]],
  // },
};
