import React, { useEffect, useRef } from "react";
const Ads = ({ adunit }) => {
  const adSlotRef = useRef(null);

  useEffect(() => {
    // Function to initialize Google Publisher Tags (GPT)
    const loadGoogleAds = () => {
      // Check if GPT script is already loaded
      if (!window.googletag) {
        window.googletag = { cmd: [] }; // Initialize GPT
      }

      // Load GPT script if not already loaded
      const gptScript = document.createElement("script");
      gptScript.src = "https://www.googletagservices.com/tag/js/gpt.js";
      gptScript.async = true;

      gptScript.onload = () => {
        window.googletag.cmd.push(() => {
          // Define the ad slot with the specified ad unit and sizes
          window.googletag
            .defineSlot(`33830838/${adunit.adUnit}`, adunit.sizes, adSlotRef.current.id)
            .addService(window.googletag.pubads());

          // Enable services and display the ad
          window.googletag.enableServices();
          window.googletag.display(adSlotRef.current.id);
        });
      };

      document.body.appendChild(gptScript); // Append the GPT script to the document
    };

    // Load the GPT script and initialize the ad slot
    loadGoogleAds();

    return () => {
      // Clean up the ad slot to avoid memory leaks
      if (window.googletag && window.googletag.pubads().clear) {
        window.googletag.pubads().clear([adSlotRef.current.id]);
      }
    };
  }, [adunit.adUnit, adunit.sizes]); // Dependencies ensure effect runs when ad unit or sizes change

  return (
    <div
      className={`my-4 flex items-center justify-center ${
        adunit.adUnit === "SM_Desktop_SP_Top_970x90" && "mobile:hidden"
      }`}
      style={{ height: `${adunit.sizes[0][1]}px` }} // Set the height based on ad size
    >
      <div ref={adSlotRef} id={adunit.adUnit} /> {/* Reference for the ad slot */}
    </div>
  );
};

export default Ads;
