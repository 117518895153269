import { renderIsomorphicComponent, renderComponent, renderBreakingNews } from "@quintype/framework/client/start";
import get from "lodash/get";

import { pickComponent } from "../isomorphic/pick-component";
import { Footer } from "../isomorphic/components/layouts/footer";
import { TopAd } from "../isomorphic/components/ads/top-ad";
import { Header } from "../isomorphic/components/layouts/header";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  const pageType = get(store.getState(), ["qt", "pageType"], null);

  if (pageType !== "signin") {
    renderComponent(Header, "header", store, hydrate);
    renderComponent(Footer, "footer", store, hydrate);
  }
  // renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  const enableAds = get(store.getState(), ["qt", "config", "ads-config", "dfp_ads", "enable_ads"]);
  const pageType = get(store.getState(), ["qt", "pageType"], null);
  const placeholderDelay = parseInt(
    get(store.getState(), ["qt", "config", "publisher-attributes", "placeholder_delay"])
  );

  enableAds && pageType !== "profile-page" && pageType !== "user-login" && renderComponent(TopAd, "top-ad", store);
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell,
  });
}
