import React, { useState } from "react";
import { Link } from "@quintype/components";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { Bar3, CloseIcon, SearchIcon } from "../icons";
import { OPEN_HAMBURGER_MENU } from "../../../store/actions";
import { authSignOut } from "../../../../../server/helpers/auth";

export const Sidebar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const menu = get(getState, ["qt", "data", "navigationMenu", "default"], []);
  const user = get(getState, ["firebaseUser"], null);
  const isHamburgerMenuOpen = get(getState, ["isHamburgerMenuOpen"], false);
  const toggleHandler = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: !isHamburgerMenuOpen,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.replace(`/search/${searchQuery}`);
  };

  const clearStorageData = () => {
    const localStorageItems = ["metypeToken", "profileId", "userEmail"];
    const sessionStorageItems = ["SUBSCRIBED_USER", "USER_PROFILE_ID"];

    localStorageItems.forEach((item) => {
      localStorage.removeItem(item);
    });

    sessionStorageItems.forEach((item) => {
      sessionStorage.removeItem(item);
    });
  };
  const logOut = () => {
    authSignOut()
      .then((result) => {
        clearStorageData();
        setTimeout(() => {
          window.location.replace("/");
        }, 100);
        dispatch({
          type: OPEN_HAMBURGER_MENU,
          isHamburgerMenuOpen: false,
        });
        dispatch({
          type: FIREBASE_USER,
          firebaseUser: null,
        });
      })
      .catch((err) => {});
  };

  const handleClick = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: false,
    });
  };
  const MenuItem = ({ item }) => {
    const sulg = item["url"].split("/").pop();
    return (
      <li onClick={() => handleClick()}>
        <div>
          <a
            href={`/section/${sulg}`}
            key={item["id"]}
            data-hs-overlay="#hs-overlay-unstyled"
            aria-controls="hs-overlay-unstyled"
            aria-label="Toggle navigation"
            className="flex items-center gap-x-3.5 rounded-md px-3 py-2 text-sm font-[500] text-grey hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
          >
            {item["title"]}
          </a>
        </div>
      </li>
    );
  };

  return (
    <div className="hidden mobile:block">
      <div type="button" aria-label="Toggle navigation" onClick={() => toggleHandler()}>
        <Bar3 />
      </div>

      <div className={`fixed left-0 bottom-0 top-0 z-[60] w-[100%] ${!isHamburgerMenuOpen ? "hidden" : ""}`}>
        <div
          className={`flex h-[100%] w-[370px] flex-col gap-4 overflow-y-auto bg-white px-2 pb-5 pt-3 transition-transform duration-300 dark:bg-zinc-900 mobile:w-[75%] lg:bottom-0 lg:right-auto lg:block lg:translate-x-0`}
        >
          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => {
                dispatch({
                  type: OPEN_HAMBURGER_MENU,
                  isHamburgerMenuOpen: false,
                });
              }}
              className="h-8 w-8"
              aria-label="Toggle navigation"
            >
              <span className="sr-only">Close Sidebar</span>
              <CloseIcon />
            </button>
          </div>
          {/* search */}
          <div className="flex w-full justify-center px-3">
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="relative flex w-full items-center justify-center text-gray-600"
            >
              <input
                type="search"
                name="sidebar_search"
                autoComplete="off"
                placeholder="Search"
                className={`w-[100%] rounded border-none bg-[#f2f2f2] px-2 py-1 text-sm leading-6 text-black focus:border-none focus:outline-none focus:ring-0`}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                type="submit"
                className={`${searchQuery === "" ? "block " : "hidden"} absolute right-4 text-gray-300`}
                aria-label="search_btn"
              >
                <SearchIcon />
              </button>
            </form>
          </div>

          <nav className="">
            <ul>
              {menu.map((item) => (
                <MenuItem item={item} key={item.id} />
              ))}
            </ul>
          </nav>
          {!user && (
            <div className="flex items-center gap-4 px-3">
              <Link
                href="https://join.swarajyamag.com/?utm_source=direct&utm_medium=website&utm_campaign=storypage"
                target={"_blank"}
              >
                <button
                  className="rounded-full bg-primary px-5 py-1 font-satoshi text-sm font-medium leading-6 text-white"
                  aria-label="subscribe_btn"
                  data-hs-overlay="#hs-overlay-unstyled"
                  id="subscribe_btn_nav"
                >
                  Subscribe
                </button>
              </Link>
              <Link href={`/signin`}>
                <button
                  className="rounded-full bg-primary px-5 py-1 font-satoshi text-sm font-medium leading-6 text-white"
                  aria-label="signin_btn"
                  data-hs-overlay="#hs-overlay-unstyled"
                  aria-controls="hs-overlay-unstyled"
                >
                  Sign In
                </button>
              </Link>
            </div>
          )}
          {user && (
            <div className="flex items-center gap-4 px-3">
              <a href={`/profile`} data-hs-overlay="#hs-overlay-unstyled" aria-controls="hs-overlay-unstyled">
                <button
                  className="rounded-full bg-primary px-5 py-1 font-satoshi text-sm font-medium leading-6 text-white"
                  aria-label="profile_btn"
                  data-hs-overlay="#hs-overlay-unstyled"
                  aria-controls="hs-overlay-unstyled"
                  onClick={() => handleClick()}
                >
                  Profile
                </button>
              </a>
              <button
                className="rounded-full bg-primary px-5 py-1 font-satoshi text-sm font-medium leading-6 text-white"
                aria-label="signout_btn"
                data-hs-overlay="#hs-overlay-unstyled"
                aria-controls="hs-overlay-unstyled"
                onClick={logOut}
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
