import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  OAuthProvider,
  confirmPasswordReset,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDpWaYoHEOQLmNvyBnJdVBcvcironvYVHk",
  authDomain: "auth.swarajyamag.com",
  projectId: "skilful-air-122506",
  messagingSenderId: "skilful-air-122506.appspot.com",
  appId: "1:791654237836:web:b3205254cf52ab44805da9",
  measurementId: "G-Y0C0F76W79",
};

const firebaseApp = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
const provider2 = new OAuthProvider("apple.com");

provider.setCustomParameters({
  prompt: "select_account",
});
const auth = getAuth();

const currentUser = auth.currentUser;
export { currentUser, firebaseApp };
export async function signInWithGoogle() {
  const result = await signInWithPopup(auth, provider);
  return result;
}
export async function signInWithApple() {
  const result = await signInWithPopup(auth, provider2);
  return result;
}
export async function authSignOut() {
  await auth.signOut();
}
export function authCheck() {
  auth.onAuthStateChanged((user) => {
    if (user) {
      return user;
    } else {
      return null;
    }
  });
}

export async function emailSignIn(email, password) {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
export async function passwordLessSignIn(email) {
  const actionCodeSettings = {
    url: "https://swarajyamag.com/",
    handleCodeInApp: true,
    iOS: {
      bundleId: "com.swarajyamag.mobile.ios",
    },
    android: {
      packageName: "com.swarajyamag.mobile.android",
      installApp: true,
      minimumVersion: "12",
    },
    dynamicLinkDomain: "swarajyamag.page.link",
  };
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    return true;
  } catch (error) {
    console.log("sendSignInLink error", error);
    throw error;
  }
}
export async function completePasswordLessSignIn(code, email) {
  try {
    const result = await signInWithEmailLink(auth, email, code);
    return result;
  } catch (error) {
    console.log("signInWithEmailLink error", error);
    throw error;
  }
}

export async function emailSignUp(email, password, name) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(res.user, {
      displayName: name,
    })
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function checkUserExistance(email) {
  let result;
  // await fetchSignInMethodsForEmail(auth, email)
  //   .then((res) => {
  //     result = res;
  //   })
  //   .catch((err) => {
  //     console.log(`Error in check email ${err}`);
  //     result = null;
  //     throw err;
  //   });
  await createUserWithEmailAndPassword(auth, email, "123456")
    .then((res) => (result = false))
    .catch((err) => {
      if (err.code === "auth/email-already-in-use") {
        result = true;
      }
    });
  return result;
}

export async function sendEmailToResetPassword(email) {
  let result;
  await sendPasswordResetEmail(auth, email)
    .then((res) => {
      result = "success";
    })
    .catch((err) => {
      console.log(`Error in sending email ${err}`);
      result = null;
      throw err;
    });

  return result;
}

export async function completeResetPassword(code, password) {
  let result;
  await confirmPasswordReset(auth, code, password)
    .then((res) => {
      result = "success";
    })
    .catch((err) => {
      console.log(`Error in completely reset password ${err}`);
      result = null;
      throw err;
    });

  return result;
}
