import React, { useEffect, useRef, useState } from "react";
import { SearchIcon } from "../icons";
import "./search.m.css";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

export const Search = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const user = get(getState, ["firebaseUser"], null);
  const searchRef = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) window.location.replace(`/search/${searchQuery}`);
  };

  useEffect(() => {
    showSearch && searchRef.current.focus();
  }, [showSearch]);

  return (
    <div className="flex items-center justify-end gap-4 mobile:hidden lg:flex-1">
      <form onSubmit={(e) => handleSubmit(e)} className="relative flex items-end justify-center text-gray-600">
        <input
          type="search"
          ref={searchRef}
          name="search"
          autoComplete="off"
          placeholder="Search"
          className={`rounded-full border-none bg-white px-2 py-1 text-sm leading-6 text-black focus:border-none focus:outline-none focus:ring-0 ${
            searchQuery === "" ? "" : "w-[200px]"
          }`}
          styleName={searchQuery === "" && "search_input"}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          type="button"
          className={`${searchQuery === "" ? "block " : "hidden"} absolute right-1 text-gray-300`}
          onClick={() => {
            setShowSearch(!showSearch);
          }}
          aria-label="search_btn"
        >
          <SearchIcon />
        </button>
      </form>
      {/* {user !== null ? (
        <DropdownMenu />
      ) : (
        <div>
          <Link href={`/signin`}>
            <ProfileAvatarWhite />
          </Link>
        </div>
      )}
      {!user && (
        <Link
          href="https://join.swarajyamag.com/?utm_source=direct&utm_medium=website&utm_campaign=storypage"
          target={"_blank"}
        >
          <button
            className="rounded-full bg-white px-5 py-1 font-satoshi text-xs leading-6 text-black"
            aria-label="subscribe_btn"
            // onClick={HomeScreenSubscribeBtn}
          >
            Subscribe
          </button>
        </Link>
      )} */}
    </div>
  );
};
