import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { object } from "prop-types";
import { Link } from "@quintype/components";
import { FacebookIcon, TwitterIcon, InstaIcon, LinkedInIcon } from "../../Icons/Icon";

const FooterBase = () => {
  return (
    <footer className="mt-5">
      <div className="bg-[#f1f1f1] dark:bg-zinc-800">
        <div className="container mx-auto  my-0 max-w-[1080px] p-4 py-8">
          <div className="flex flex-wrap py-8">
            <div className="mb-8 mr-[5px] w-full md:mb-0 md:w-[25%]">
              <p className="mb-4 text-xl font-semibold">About Swarajya</p>
              <p className="text-[14px] text-[#4b4b4b] dark:text-[#f1f1f196]">
                Shaping the modern Indian&#39;s worldview, speaking on behalf of those invested in the cultural and
                economic prosperity of India. Published since 1956.
              </p>
              <br />

              <p className="text-[14px] text-[#4b4b4b] dark:text-[#f1f1f196]">
                Swarajya is a publication by Kovai Media Private Limited.
                {/* Swarajya - a big tent for liberal right of centre discourse that
              reaches out, engages and caters to the new India. */}
              </p>

              <a className="mt-4 text-sm text-[#ca4242]" href="mailto:editor@swarajyamag.com" target="_top">
                editor@swarajyamag.com
              </a>
            </div>

            <div className="mb-8 mobile:hidden md:mb-0 md:w-[18%]">
              <p className="mb-4 text-xl font-semibold">Useful Links</p>
              <ul className="text-[#4b4b4b] dark:text-[#f1f1f196]">
                <li>
                  <a href={`/about-us`} className="text-[14px]">
                    About Us
                  </a>
                </li>
                <li>
                  <a href={`/support`} className="text-[14px]">
                    Subscriptions Support
                  </a>
                </li>
                <li>
                  <a href={`/editorial-philosophy`} className="text-[14px]">
                    Editorial Philosophy
                  </a>
                </li>

                <li>
                  <a href={`downloads/Swarajya-Presskit.pdf`} className="text-[14px]">
                    Press Kit
                  </a>
                </li>
              </ul>
            </div>

            <div className=" mb-8 mt-[45px]  mobile:hidden md:mb-0 md:w-[18%]">
              <ul className="text-[#4b4b4b] dark:text-[#f1f1f196]">
                <li>
                  <a href={`/privacy-policy`} className="text-[14px]">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href={`/terms-of-use`} className="text-[14px]">
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a href={`/code-of-conduct`} className="text-[14px]">
                    Code of Conduct
                  </a>
                </li>
                <li>
                  <a href={`/plagiarism-policy`} className="text-[14px]">
                    Plagiarism Policy
                  </a>
                </li>

                <li>
                  <a href={`/refund-cancellation-policy`} className="text-[14px]">
                    Refund &amp; Cancellation Policy
                  </a>
                </li>
              </ul>
            </div>

            <div className="hidden mobile:block">
              <div className="flex">
                <div className="mb-8 md:mb-0 md:w-[18%]">
                  <p className="mb-4 text-xl font-semibold">Useful Links</p>
                  <ul className="text-[#4b4b4b] dark:text-[#f1f1f196]">
                    <li>
                      <a href={`/about-us`} className="text-[14px]">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href={`/support`} className="text-[14px]">
                        Subscriptions Support
                      </a>
                    </li>
                    <li>
                      <a href={`/editorial-philosophy`} className="text-[14px]">
                        Editorial Philosophy
                      </a>
                    </li>

                    <li>
                      <a href={`downloads/Swarajya-Presskit.pdf`} className="text-[14px]">
                        Press Kit
                      </a>
                    </li>
                  </ul>
                </div>

                <div className=" mb-8 mt-[45px] md:mb-0 md:w-[18%]">
                  <ul className="text-[#4b4b4b] dark:text-[#f1f1f196]">
                    <li>
                      <a href={`/privacy-policy`} className="text-[14px]">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href={`/terms-of-use`} className="text-[14px]">
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a href={`/code-of-conduct`} className="text-[14px]">
                        Code of Conduct
                      </a>
                    </li>
                    <li>
                      <a href={`/plagiarism-policy`} className="text-[14px]">
                        Plagiarism Policy
                      </a>
                    </li>

                    <li>
                      <a href={`/refund-cancellation-policy`} className="text-[14px]">
                        Refund &amp; Cancellation Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mb-8  md:mb-0 md:w-[18%]">
              <p className="mb-4 text-xl font-semibold">Participate</p>
              <ul className="text-[#4b4b4b] dark:text-[#f1f1f196]">
                <li>
                  <a href={`/contact-us`} className="text-[14px]">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href={`/write-for-us`} className="text-[14px]">
                    Write for us
                  </a>
                </li>
                <li>
                  <a href={`/style-guide`} className="text-[14px]">
                    Style Guide
                  </a>
                </li>
                {/* <li>
                <a
                  href="/advertise"
                  className="text-[14px]"
                >
                  Advertise
                </a>
              </li> */}
                <li>
                  <a href={`/jobs`} className="text-[14px]">
                    Jobs
                  </a>
                </li>
              </ul>
            </div>

            <div className="mb-8 ml-[10px] md:mb-0 md:w-[18%]">
              <p className="mb-4  text-xl font-semibold">Stay Connected</p>
              <ul>
                <li>
                  <div className="flex">
                    <a
                      href="https://www.facebook.com/swarajyamag/?fref=ts"
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label="FB share btn"
                    >
                      <FacebookIcon />
                      {/* {process.browser ? <FacebookIcon /> : null} */}
                    </a>
                    <div>
                      <a
                        href="https://www.instagram.com/swarajya_mag/"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Insta share btn"
                      >
                        <InstaIcon />
                        {/* {process.browser ? <InstaIcon /> : null} */}
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://twitter.com/SwarajyaMag"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Twitter share btn"
                      >
                        <TwitterIcon />
                        {/* {process.browser ? <TwitterIcon /> : null} */}
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.linkedin.com/company/swarajya"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="LinkedIn share btn"
                      >
                        <LinkedInIcon />
                        {/* {process.browser ? <LinkedInIcon /> : null} */}
                      </a>
                    </div>
                  </div>
                </li>
                <li className="my-1">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.swarajyamag.mobile.android&hl=en"
                    target="_blank"
                  >
                    <img
                      src={`https://articleimage.sgp1.cdn.digitaloceanspaces.com/articlespace/assets/download-androidCompressed.png`}
                      alt="Andriod Logo"
                      width={135}
                      height={42}
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a id="ios-download" target="_blank" href="https://itunes.apple.com/us/app/swarajya/id1135634262">
                    <img
                      src={`https://articleimage.sgp1.cdn.digitaloceanspaces.com/articlespace/assets/download-iosCompressed.png`}
                      alt="IOS Logo"
                      width={135}
                      height={42}
                      loading="lazy"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

// function mapStateToProps(state) {
//   return {
//     menu: get(state, ["qt", "data", "navigationMenu", "footer"], []),
//   };
// }

// FooterBase.propTypes = {
//   menu: object,
// };

// export const Footer = connect(mapStateToProps, null)(FooterBase);
export const Footer = FooterBase;
