import React, { useEffect, useRef, useState } from "react";
import { Link } from "@quintype/components";
import { useSelector } from "react-redux";
import get from "lodash/get";

export const MenuItem = ({ item, onMenuClick }) => {
  const sulg = item["url"].split("/").pop();
  return (
    <Link href={`/section/${sulg}`} key={item["id"]} className="w-full">
      <li
        onClick={onMenuClick}
        className="rounded-sm px-3 py-2 text-sm hover:bg-gray-100 text-black dark:text-gray-400  dark:hover:bg-gray-700 dark:hover:text-gray-300"
      >
        {" "}
        {item["title"]}{" "}
      </li>
    </Link>
  );
};

const Bottom = () => {
  const ourViewRef = useRef();
  const ourViewButtonRef = useRef();
  const getState = useSelector((state) => state);
  const pageType = get(getState, ["qt", "pageType"], null);
  const menu = get(getState, ["qt", "data", "navigationMenu", "default"], []);
  const latestMagSlug = get(getState, ["qt", "data", "navigationMenu", "latestMag"], []);
  const [ourViewOpen, setOurViewOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ourViewRef.current &&
        !ourViewRef.current.contains(event.target) &&
        ourViewButtonRef.current &&
        !ourViewButtonRef.current.contains(event.target)
      ) {
        setOurViewOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const nav_items = [
    {
      name: "Magazine",
      slug: `/issue/${latestMagSlug[0].title}`,
      split: true,
    },
    {
      name: "Headlines",
      slug: `/headlines`,
      split: true,
    },
    {
      name: "Store",
      slug: "https://store.swarajyamag.com",
      split: false,
    },
  ];

  const isActive = (path) => {
    return `/${pageType}` === path ? "text-[#ca4242]" : "text-black dark:text-white";
  };

  const RenderNavItems = ({ item }) => {
    return (
      <li>
        <Link href={`${item.slug}`} className={`text-sm hover:text-[#ca4242] ${isActive(item.slug)}`}>
          <span
            className={`${item.split && "border-r"} border-[#ca4242] px-3`}
            aria-label={`Check out our most used page ${item.name}`}
          >
            {item.name}
          </span>
        </Link>
      </li>
    );
  };

  return (
    <nav className="font-satoshi">
      <ul className="mx-auto mt-2 mb-4 flex max-w-7xl items-end justify-center px-3 pb-1 pt-4" aria-label="Global">
        <li
          onClick={() => {
            setOurViewOpen(!ourViewOpen);
          }}
          ref={ourViewButtonRef}
          className="cursor-pointer relative inline-block text-sm text-black dark:text-white"
        >
          <span className="border-r border-[#ca4242] px-3 hover:text-[#ca4242] mobile:hidden">Our Views</span>
        </li>
        {nav_items.map((item, i) => (
          <RenderNavItems item={item} key={i} />
        ))}
      </ul>

      {ourViewOpen && (
        <div
          ref={ourViewRef}
          className={`left-0 z-50 w-full absolute bg-white p-2 dark:divide-gray-700 dark:border-gray-700 dark:bg-zinc-900 sm:shadow-md  sm:dark:border`}
        >
          <nav>
            <ul className="mx-auto my-0 w-[50%] items-center gap-3 sm:grid sm:grid-flow-col sm:grid-rows-4">
              {menu.map((item) => {
                return <MenuItem item={item} onMenuClick={() => setOurViewOpen(false)} key={item.id} />;
              })}
            </ul>
          </nav>
        </div>
      )}
    </nav>
  );
};

export { Bottom };
