// import React, { useEffect, useState } from "react";
// import { MoonIcon, SunIcon } from "../icons";

// export const DarkModeToggler = () => {
//   const [theme, setTheme] = useState(null);

//   useEffect(() => {
//     if (theme !== null) {
//       localStorage.setItem("theme", theme);
//       // document.documentElement.classList.add(theme);
//     }
//   }, [theme]);

//   const darkModeHandler = () => {
//     setTheme(theme === "dark" ? "light" : "dark");
//     document.documentElement.classList.toggle("dark");
//   };

//   useEffect(() => {
//     if (localStorage) {
//       const localTheme = localStorage.getItem("theme") || "light";
//       setTheme(localTheme);
//     }

//     document.body.classList.add(
//       "flex",
//       "h-screen",
//       "flex-col",
//       "text-gray-800",
//       "transition-all",
//       "duration-700",
//       "selection:bg-[#ca4240]",
//       "selection:text-white",
//       "dark:bg-zinc-900",
//       "dark:text-white"
//     );
//   }, []);

//   return (
//     <div className="flex lg:flex-1">
//       <button
//         onClick={() => darkModeHandler()}
//         className="text-lg font-semibold leading-6 text-white"
//         aria-label="theme_btn"
//         id="dark_mode_toggle_btn"
//       >
//         {theme === "dark" ? (
//           <>
//             <SunIcon />
//           </>
//         ) : (
//           <MoonIcon />
//         )}
//       </button>
//     </div>
//   );
// };

import React, { useEffect, useState } from "react";
import { MoonIcon, SunIcon } from "../icons";

export const DarkModeToggler = () => {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("theme") || "light";
    }
    return "light";
  });

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "theme") {
        setTheme(e.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    document.body.classList.add(
      "flex",
      "h-screen",
      "flex-col",
      "text-gray-800",
      "transition-all",
      "duration-700",
      "selection:bg-[#ca4240]",
      "selection:text-white",
      "dark:bg-zinc-900",
      "dark:text-white"
    );
  }, []);

  const darkModeHandler = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  return (
    <div className="flex lg:flex-1">
      <button
        onClick={darkModeHandler}
        className="text-lg font-semibold leading-6 text-white"
        aria-label="theme_btn"
        id="dark_mode_toggle_btn"
      >
        {theme === "dark" ? <SunIcon /> : <MoonIcon />}
      </button>
    </div>
  );
};
