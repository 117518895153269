import { chunk } from "lodash";
import { PAGE_TYPE } from "./constants";
import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "home", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.COLLECTION_PAGE]: { chunk: "list", component: "CollectionPage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.FORM_PAGE]: { chunk: "list", component: "FormPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.ABOUT_PAGE]: { chunk: "about", component: "AboutUsPage" },
    [PAGE_TYPE.CATALOG_PAGE]: { chunk: "list", component: "CatalogPage" },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.SUBSCRIPTION_PAGE]: { chunk: "list", component: "SubscriptionPage" },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "home", component: "HomePagePreview" },
    [PAGE_TYPE.PROFILE_PAGE]: { chunk: "list", component: "ProfilePage" },
    [PAGE_TYPE.USER_LOGIN]: { chunk: "list", component: "UserLoginPage" },
    [PAGE_TYPE.UGC_PAGE]: { chunk: "list", component: "UgcPage" },
    [PAGE_TYPE.SIGNIN]: { chunk: "access", component: "SignInPage" },
    [PAGE_TYPE.SIGNUP]: { chunk: "access", component: "SignupPage" },
    [PAGE_TYPE.RESET_PASSWORD_PAGE]: { chunk: "access", component: "ResetPassword" },
    [PAGE_TYPE.FORGOT_PASSWORD_PAGE]: { chunk: "access", component: "ForgotPassword" },
    [PAGE_TYPE.MAGIC_LINK]: { chunk: "access", component: "MagicLink" },
    [PAGE_TYPE.ALL_ISSUES]: { chunk: "list", component: "Magazine" },
    [PAGE_TYPE.ISSUE]: { chunk: "list", component: "IssuePage" },
    [PAGE_TYPE.TEAM_PAGE]: { chunk: "access", component: "TeamPage" },
    [PAGE_TYPE.HEADLINES]: { chunk: "list", component: "Headlines" },
    [PAGE_TYPE.BOOKMARKS]: { chunk: "list", component: "Bookmarks" },
    default: { chunk: "list", component: "NotFoundPage" },
  },
  {
    home: () => import(/* webpackChunkName: "home" */ "./component-bundles/home.js"),
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js"),
    access: () => import(/* webpackChunkName: "access" */ "./component-bundles/access.js"),
    about: () => import(/* webpackChunkName: "story" */ "./component-bundles/about.js"),
  }
);

export { pickComponent, getChunkName };
