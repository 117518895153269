import React, { useRef, useEffect } from "react";
import { authSignOut } from "../../../../../server/helpers/auth";
import { Link } from "@quintype/components";
import { FIREBASE_USER } from "../../../store/actions";
import { useDispatch } from "react-redux";

const DropdownMenu = ({ setDropDownOpen }) => {
  const dispatch = useDispatch();

  const dropDownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropDownOpen]);

  const clearStorageData = () => {
    const localStorageItems = ["metypeToken", "profileId", "userEmail"];
    const sessionStorageItems = ["SUBSCRIBED_USER", "USER_PROFILE_ID"];

    localStorageItems.forEach((item) => {
      localStorage.removeItem(item);
    });

    sessionStorageItems.forEach((item) => {
      sessionStorage.removeItem(item);
    });
  };

  const logOut = () => {
    authSignOut()
      .then((result) => {
        dispatch({
          type: FIREBASE_USER,
          firebaseUser: null,
        });
        clearStorageData();

        setTimeout(() => {
          window.location.replace("/");
        }, 100);
        setDropDownOpen(false);
      })
      .catch((err) => {});
  };

  const onMenuClick = () => setDropDownOpen(false);

  return (
    <div ref={dropDownRef} className={`absolute top-3.5 right-1 z-10 rounded-md bg-white p-2 shadow-lg mt-2`}>
      <div className="flex flex-col py-1">
        <Link href={`/profile`} className=" px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div onClick={onMenuClick}>Profile</div>
        </Link>
        {/* {hasTeam && (
            <Link href={`/team`} prefetch={false} className=" px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              Team
            </Link>
          )} */}
        <Link href={`/bookmarks`} prefetch={false} className=" px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div onClick={onMenuClick}>Bookmarks</div>
        </Link>
        <Link href={`/auth/forgot-password`} className=" px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <div onClick={onMenuClick}>Reset Password</div>
        </Link>
        <button
          onClick={logOut}
          id="logout_btn"
          className="cursor-pointer px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export { DropdownMenu };
