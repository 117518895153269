import React from "react";
import { Link } from "@quintype/components";
import { string } from "prop-types";
import "./app-logo.m.css";

const AppLogo = () => {
  return (
    <div className="flex justify-center lg:flex-1">
      <Link href={`/`}>
        <img
          src={
            "https://articleimage.sgp1.cdn.digitaloceanspaces.com/articlespace/assets/Swarajya-logo-whiteCompressed.png"
          }
          width={150}
          height={32}
          className="w-[150px] mobile:w-[120]"
          alt={"Swarajya Logo"}
        />
      </Link>
    </div>
  );
};

AppLogo.propTypes = {
  width: string,
};
export { AppLogo };
