import React from "react";
import "./auth-header.m.css";
import { Link } from "@quintype/components";

export const AuthHeader = () => {
  return (
    <>
      <div className="min-h-[65px] w-[100%] bg-[#f1f1f1] dark:bg-zinc-800"></div>
      <Link href={`/`}>
        <h1 styleName="logo" aria-hidden="true" className="mx-auto mt-[-30px] h-[50px] w-[200px]" />
      </Link>
    </>
  );
};
