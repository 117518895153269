import React, { useState, useEffect } from "react";
import axios from "axios";
import { USER_PROFILE_ID, SUBSCRIBED_USER } from "../../components/store/actions";
import get from "lodash/get";
import { useSelector } from "react-redux";

export const getUserData = async (email) => {
  try {
    let url = `https://userstream.swarajyamag.com/api/readers?email=${email}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer 9756c92fe386223d61e52f938896b91131ec1234b8fd8892d2e0d1ed574772529413148d9392519664ae1e4661c61b87a0dbd725249a4131e8c0382fa297b56ab3916c0069a48cd7d9f60ac80e2a99733a4204ba0a2867c949e450c39b2d6f7d386bf01611112a010c17d4e14a6b833cf1ee65fd5460bd08525e5f1ab005702a`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 404) {
      return {
        profileId: null,
      };
    }

    return response.data;
  } catch (error) {
    console.error(error.message);
    if (error.response && error.response.status === 404) {
      return {
        profileId: null,
      };
    }
    throw error;
  }
};
export const getSubscriptionData = async (email) => {
  try {
    const response = await axios.get(`https://subsapi.swarajyamag.com/api/subscriptions`, {
      params: { email },
    });

    if (response.data.subscriptions && response.data.subscriptions.length !== 0) {
      return response.data.subscriptions[0].active;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching subscription data:", error);
    return false;
  }
};
export const SessionDataMang = async (dispatch) => {
  const getState = useSelector((state) => state);
  const user = get(getState, ["firebaseUser"], null);
  const getEmail = () => {
    if (typeof window !== "undefined" && window.localStorage) {
      const storedUser = localStorage.getItem("userEmail");
      return storedUser ? JSON.parse(storedUser) : null;
    }
    return null;
  };

  // const email = user === null ? getEmail() : user?.email;
  const email = user?.email || getEmail();

  const subscribedUser = JSON.parse(sessionStorage.getItem("SUBSCRIBED_USER"));
  const userProfileId = JSON.parse(sessionStorage.getItem("USER_PROFILE_ID"));

  if (!subscribedUser) {
    if (email) {
      try {
        let subscriptionData = await getSubscriptionData(email);

        if (subscriptionData) {
          dispatch({ type: SUBSCRIBED_USER, isSubscribedUser: subscriptionData });
          sessionStorage.setItem("SUBSCRIBED_USER", JSON.stringify(subscriptionData));
        }
      } catch (error) {
        console.error("Failed to fetch subscription data", error);
      }
    }
  }

  if (!userProfileId) {
    if (email) {
      try {
        const userProfile = await getUserData(email);
        if (userProfile && userProfile.profileId) {
          dispatch({ type: USER_PROFILE_ID, userProfileID: userProfile.profileId });
          sessionStorage.setItem("USER_PROFILE_ID", JSON.stringify(userProfile.profileId));
        }
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    }
  }
};
