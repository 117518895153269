import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from "../helpers/auth";
import { FIREBASE_USER, USER_PROFILE_ID, SUBSCRIBED_USER } from "../../isomorphic/components/store/actions";
import axios from "axios";

const auth = getAuth(firebaseApp);

export const getUserData = async (email) => {
  try {
    let url = `https://userstream.swarajyamag.com/api/readers?email=${email}`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer 9756c92fe386223d61e52f938896b91131ec1234b8fd8892d2e0d1ed574772529413148d9392519664ae1e4661c61b87a0dbd725249a4131e8c0382fa297b56ab3916c0069a48cd7d9f60ac80e2a99733a4204ba0a2867c949e450c39b2d6f7d386bf01611112a010c17d4e14a6b833cf1ee65fd5460bd08525e5f1ab005702a`,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    if (res.status === 404) {
      return {
        profileId: null,
      };
    }
    return data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getSubscriptionData = async (email) => {
  try {
    const response = await axios.get(`https://subsapi.swarajyamag.com/api/subscriptions`, {
      params: { email },
    });

    if (response.data.subscriptions && response.data.subscriptions.length !== 0) {
      return response.data.subscriptions[0].active;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching subscription data:", error);
    return false;
  }
};

export const checkAuthStatus = (dispatch) => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    if (user) {
      dispatch({ type: FIREBASE_USER, firebaseUser: user });

      // const subscriptionData = await getSubscriptionData(user.email);
      // dispatch({ type: SUBSCRIBED_USER, isSubscribedUser: subscriptionData });
      // const userProfile = await getUserData(user.email);
      // dispatch({ type: USER_PROFILE_ID, userProfileID: userProfile.profileId });
    } else {
      dispatch({ type: FIREBASE_USER, firebaseUser: null });
      dispatch({ type: SUBSCRIBED_USER, isSubscribedUser: false });
    }
  });

  return unsubscribe;
};
