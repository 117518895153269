import {
  OPEN_HAMBURGER_MENU,
  OPEN_SEARCHBAR,
  MEMBER_UPDATED,
  IS_OPEN_LOGIN_FORM,
  OUR_VIEW_OPEN,
  FIREBASE_USER,
  SUBSCRIBED_USER,
  BOOKMARK_DATA,
  USER_PROFILE_ID,
} from "./actions";

function hamburgerMenuReducer(state = false, action) {
  switch (action.type) {
    case OPEN_HAMBURGER_MENU:
      return action.isHamburgerMenuOpen;
    default:
      return state;
  }
}
function ourViewOpenReducer(state = false, action) {
  switch (action.type) {
    case OUR_VIEW_OPEN:
      return action.isOurViewOpen;
    default:
      return state;
  }
}

function searchBarReducer(state = false, action) {
  switch (action.type) {
    case OPEN_SEARCHBAR:
      return action.isSearchBarOpen;
    default:
      return state;
  }
}

function memberReducer(state = null, action) {
  switch (action.type) {
    case MEMBER_UPDATED:
      return action.member;

    default:
      return state;
  }
}

function firebaseUserReducer(state = null, action) {
  switch (action.type) {
    case FIREBASE_USER:
      return action.firebaseUser;

    default:
      return state;
  }
}

function subscribedUserReducer(state = null, action) {
  switch (action.type) {
    case SUBSCRIBED_USER:
      return action.isSubscribedUser;

    default:
      return state;
  }
}
function userProfileIDReducer(state = null, action) {
  switch (action.type) {
    case USER_PROFILE_ID:
      return action.userProfileID;

    default:
      return state;
  }
}

function loginReducer(state = false, action) {
  switch (action.type) {
    case IS_OPEN_LOGIN_FORM:
      return action.payload;
    default:
      return state;
  }
}

const userIntialState = {
  jwt_token: null,
};

function userReducer(state = userIntialState, action) {
  switch (action.type) {
    case "METYPE_JWT_TOKEN":
      return {
        ...state,
        jwt_token: action.payload,
      };

    default:
      return state;
  }
}
function bookmarkDataReducer(state = null, action) {
  switch (action.type) {
    case BOOKMARK_DATA:
      return [action.bookmarkData];

    default:
      return state;
  }
}

export const REDUCERS = {
  isHamburgerMenuOpen: hamburgerMenuReducer,
  isSearchBarOpen: searchBarReducer,
  member: memberReducer,
  firebaseUser: firebaseUserReducer,
  isLoginOpen: loginReducer,
  userReducer: userReducer,
  isOurViewOpen: ourViewOpenReducer,
  isSubscribedUser: subscribedUserReducer,
  userProfileID: userProfileIDReducer,
  bookmarkData: bookmarkDataReducer,
};
